// Declare the global values used in this Component
declare var angular: angular.IAngularStatic;

// Libraries
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { downgradeComponent } from '@angular/upgrade/static';
import { DomSanitizer } from '@angular/platform-browser';
import { switchMap, tap, debounceTime } from 'rxjs/operators';

// Custom services/components
import { AgencyResource } from '../../communication/resources/agency-resource';
import { AdvertiserResource } from '../../communication/resources/advertiser-resource';
import { BrandResource } from '../../communication/resources/brand-resource-factory';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'cts-quick-send-isci-panel',
    template: require('./cts-quick-send-isci-panel.component.html'),
})
export class CtsQuickSendIsciPanelComponent implements OnInit {
    // External Inputs
    @Input() spots: any|null = null;
    @Input() create: any|null = null;
    @Input() panel: any|null = null;
    @Input() thumbs: any|null = null;
    @Input() strict: Boolean|null = null;
    @Input() ordertype: string|null = null;
    @Input() existing: any|null = null;
    @Input() filtered: Boolean|null = null;
    // External Outputs
    @Output() added = new EventEmitter();
    @Output() canceled = new EventEmitter();

    public newSpotRows:FormGroup = new FormGroup({});
    public uploadingAssets:boolean = false;
    public modifiedIscis:string[] = [];
    public availableSpots: any = [];
    public hiddenSpots: Number = 0;
    public filteredAgencies: any[] = [];
    public filteredAdvertisers: any[] = [];
    public filteredBrands: any[] = [];

    constructor(
        private sanitizer: DomSanitizer,
        private agencyResource: AgencyResource,
        private advertiserResource: AdvertiserResource,
        private brandResource: BrandResource,
        private _snackBar: MatSnackBar
    ) {}

    ngOnInit() {
        let constructedFormGroup:any = {};

        for(let x = 0; x < this.spots.length; x++) {
            switch(this.ordertype) {
                case 'TV':
                    this.spots[x].locked = (this.spots[x].format !== 'SD' && this.spots[x].format !== 'HD');
                    break;
                case 'RADIO':
                    this.spots[x].locked = (this.spots[x].format !== 'RADIO');
                    break;
                case 'DIGITAL':
                    this.spots[x].locked = (this.spots[x].format === 'RADIO');
                    break;
                default:
                    // Lock everything if they are trying something funny
                    this.spots[x].locked = true;
                    break;
            }

            // Deselect the locked spots, so they don't get added
            this.spots[x].selected = !this.spots[x].locked;

            if(this.existing.includes(this.spots[x].id)) {
                this.spots[x].selected = false;
            }
    
            this.availableSpots = this.spots.filter((cur:any) => {
                return !this.existing.includes(cur.id);
            });
            this.hiddenSpots = this.spots.length - this.availableSpots.length;
        }
        
        for(let i = 0; i < this.create.length; i++) {
            this.create[i].isci = this.create[i].isci.trim();
            // Do this check first, so we can use it for the dynamic H section
            let defaultFormat = (this.ordertype === 'RADIO')? 'RADIO' : 'HD';

            // Append a string literal to prevent passing by reference
            this.modifiedIscis[this.create[i].isci] = '' + this.create[i].isci;
            if(this.strict && !this.create[i].invalid) {
                this.modifiedIscis[this.create[i].isci] = (this.modifiedIscis[this.create[i].isci].match(/[^hH]$/) && defaultFormat === 'HD') ? this.modifiedIscis[this.create[i].isci] + 'H' : this.modifiedIscis[this.create[i].isci];
                this.modifiedIscis[this.create[i].isci] = (this.modifiedIscis[this.create[i].isci].match(/[hH]$/) && defaultFormat !== 'HD') ? this.modifiedIscis[this.create[i].isci].substr(0, this.modifiedIscis[this.create[i].isci].length-1) : this.modifiedIscis[this.create[i].isci];
            }

            // If the new isci contains special characters, remove it from the list to create and add it to a list of bad ISCIs
            if(this.create[i].isci.match(/[^a-zA-Z\d;,]/)) {
                this.create[i].invalid = 'This ISCI contains invalid characters';
            } else if (this.modifiedIscis[this.create[i].isci].length > 15 && this.strict) {
                this.create[i].invalid = 'This ISCI is longer than the allowed limit';
            } else {
                this.create[i].invalid = false;
                
                constructedFormGroup[this.create[i].isci] = new FormGroup({
                    selected: new FormControl(false),
                    title: new FormControl('', Validators.required),
                    format: new FormControl(defaultFormat, Validators.required),
                    asset: new FormControl(null, Validators.required),
                    agency: new FormControl('', Validators.required),
                    advertiser: new FormControl('', Validators.required),
                    brand: new FormControl('', Validators.required),
                });

                // Setup the filter observables for a/a/b
                constructedFormGroup[this.create[i].isci].controls.agency.valueChanges
                .pipe(
                    // Don't fire the request right away, make sure the user has stopped typing
                    debounceTime(300),
                    tap(() => {}),
                    // Don't re-request data if the user has only selected an option
                    switchMap(value => {
                        if (value && typeof value === 'string') {
                            // Remove the Advertiser
                            constructedFormGroup[this.create[i].isci].controls.advertiser.setValue('');
                            // Clear autocomplete options
                            this.filteredAdvertisers[this.create[i].isci] = [];
                            return this.agencyResource.getAll({name: value, limit: 15, activeOnly: true});
                        } else {
                            return [];
                        }
                    })
                ).subscribe((agencies:any) => {
                    this.filteredAgencies[this.create[i].isci] = agencies.rows ?? [];
                });

                constructedFormGroup[this.create[i].isci].controls.advertiser.valueChanges
                .pipe(
                    // Don't fire the request right away, make sure the user has stopped typing
                    debounceTime(300),
                    // Don't re-request data if the user has only selected an option
                    switchMap(value => {
                        let agencyId = -1;
                        if (constructedFormGroup[this.create[i].isci].controls.agency.value && constructedFormGroup[this.create[i].isci].controls.agency.value.id) {
                            agencyId = constructedFormGroup[this.create[i].isci].controls.agency.value.id
                        }

                        // Remove the Brand, if it is no longer valid
                        if(constructedFormGroup[this.create[i].isci].controls.brand.value.advertiserId !== (value as any).id) {
                            constructedFormGroup[this.create[i].isci].controls.brand.setValue('');
                            // Clear autocomplete options
                            this.filteredBrands[this.create[i].isci] = [];
                        }

                        if (value && typeof value === 'string') {
                            return this.advertiserResource.getAll({
                                name: value,
                                limit: 15,
                                active: true,
                                agencyId
                            });
                        } else {
                            return [];
                        }
                    })
                ).subscribe((advertisers:any) => {
                    this.filteredAdvertisers[this.create[i].isci] = advertisers ?? [];
                });

                constructedFormGroup[this.create[i].isci].controls.brand.valueChanges
                .pipe(
                    // Don't fire the request right away, make sure the user has stopped typing
                    debounceTime(300),
                    // Don't re-request data if the user has only selected an option
                    switchMap(value => {
                        let advertiserId = 0;
                        if (constructedFormGroup[this.create[i].isci].controls.advertiser.value && constructedFormGroup[this.create[i].isci].controls.advertiser.value.id) {
                            advertiserId = constructedFormGroup[this.create[i].isci].controls.advertiser.value.id
                        }

                        if (value && typeof value === 'string') {
                            return this.brandResource.getAll({
                                name: value,
                                limit: 15,
                                active: true,
                                advertiserId
                            });
                        } else {
                            return [];
                        }
                    })
                ).subscribe((brands:any) => {
                    this.filteredBrands[this.create[i].isci] = brands ?? [];
                });
            }

            if (this.availableSpots.map((cur:any) => cur.isci).includes(this.modifiedIscis[this.create[i].isci])) {
                this.create[i].invalid = 'This spot already exists on the account';
                this.create[i].selected = false;
            }
        }

        this.newSpotRows = new FormGroup(constructedFormGroup);
    }

    handleAgencySelect(event:any, isci:any) {
        this.newSpotRows.controls[isci].get('advertiser')?.setValue('');
        this.filteredAdvertisers[isci] = [];
    }

    blurAgency(event:any, isci:any) {
        if (typeof this.newSpotRows.controls[isci].get('agency')?.value === 'string' || !this.newSpotRows.controls[isci].get('agency')?.value.id) {
            this.newSpotRows.controls[isci].get('agency')?.setValue('');
        }
    }

    handleAdvertiserSelect(event:any, isci:any) {
        this.newSpotRows.controls[isci].get('brand')?.setValue('');
        this.filteredBrands[isci] = [];
    }

    blurAdvertiser(event:any, isci:any) {
        if (typeof this.newSpotRows.controls[isci].get('advertiser')?.value === 'string' || !this.newSpotRows.controls[isci].get('advertiser')?.value.id) {
            this.newSpotRows.controls[isci].get('advertiser')?.setValue('');
        }
    }

    blurBrand(event:any, isci:any) {
        if (typeof this.newSpotRows.controls[isci].get('brand')?.value === 'string' || !this.newSpotRows.controls[isci].get('brand')?.value.id) {
            this.newSpotRows.controls[isci].get('brand')?.setValue('');
        }
    }

    cancel() {
        this.canceled.emit(true);
    }

    validAdditions() {
        let valid = true;
        for (const [, value] of Object.entries(this.newSpotRows.value)) {            
            let row = value as any;

            if (row.selected === false) {
                // If this row isn't selected, it doesn't matter if it is valid
                continue;
            }

            if (
                (!row.agency || !row.agency.id) ||
                (!row.advertiser || !row.advertiser.id) ||
                (!row.brand || !row.brand.id)
            ) {
                valid = false;
            }
        }
        return valid;
    }

    add() {
        this.create = [];
        for (const [key, value] of Object.entries(this.newSpotRows.value)) {
            let createdSpot = Object.assign({}, value as any);
            createdSpot.isci = this.modifiedIscis[key as any].toUpperCase();
            this.create.push(createdSpot);
        }
        // Send all of the spots back to the makelist controller for creation and submission
        this.added.emit({
            new: this.create.filter((spot:any) => spot.selected),
            old: this.spots.filter((spot:any) => spot.selected),
        });
    }

    checkSelect(spot:any):boolean {
        return (this.newSpotRows.value[spot.isci]) ? this.newSpotRows.value[spot.isci].selected : false;
    }

    selectRow(spot:any) {
        if(spot.locked || spot.invalid) {
            return false;
        }

        // Then update the actual form value
        this.newSpotRows.value[spot.isci].selected = !this.newSpotRows.value[spot.isci].selected;
        this.newSpotRows.patchValue(this.newSpotRows.value);

        this._checkUploadNeeded();
    }

    selectAsset(event:any, isci:string) {
        // Stop bubbling up the event
        event.stopPropagation();

        // Click on the hidden file select input
        // I feel like there is a better way to do this, but I have spent a week extra on this feature at this point
        (document.getElementById('upload_for_'+isci) as HTMLElement).click();
    }

    assetSelected(event:any, spot:any) {
        // Probably not going to be a problem, but just in case
        if(!(event && event.target && event.target.files && event.target.files[0] && event.target.files[0].name && this.validateFileFormat(event.target.files[0].name))) {
            console.log('invalid file selection');
            return false;
        }

        let selectedFile = event.target.files[0];
        
        spot.filename = selectedFile.name;
        spot.file = selectedFile;

        let valueToPatch:any = {};

        valueToPatch[spot.isci] = {
            asset: selectedFile,
            selected: true
        }

        this.newSpotRows.patchValue(valueToPatch);

        this._checkUploadNeeded();
    }

    sanitize(url:string) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }

    changeFormat(event:any, row:any) {
        if(this.strict) {
            switch(event.target.value) {
                case 'SD':
                case 'RADIO':
                case 'COMPOSITE':
                    this.modifiedIscis[row.isci] = (this.modifiedIscis[row.isci].match(/[hH]$/)) ? this.modifiedIscis[row.isci].substr(0, this.modifiedIscis[row.isci].length-1) : this.modifiedIscis[row.isci];
                    break;
                case 'HD':
                    this.modifiedIscis[row.isci] = (this.modifiedIscis[row.isci].match(/[^hH]$/)) ? this.modifiedIscis[row.isci] + 'H' : this.modifiedIscis[row.isci];
                    break;
            }
        }
        
        if (this.availableSpots.map((cur:any) => cur.isci).includes(this.modifiedIscis[row.isci])) {
            row.invalid = 'This spot already exists on the account';
            this.newSpotRows.value[row.isci].selected = false;
        } else if (this.modifiedIscis[row.isci].length > 15 && this.strict) {
            row.invalid = 'This ISCI is longer than the allowed limit';
        } else {
            row.invalid = false;
        }
    }

    private _checkUploadNeeded(){
        this.uploadingAssets = false;

        for (const [, value] of Object.entries(this.newSpotRows.value)) {
            let convertedVal = value as any;
            if(convertedVal.selected && convertedVal.asset) {
                this.uploadingAssets = true;
            }
        }
    }

    // @ts-ignore: Not Really unused, because it's in the template
    private _objectName(obj: any) {
        return obj && obj.name ? obj.name : '';
    }

    // function to validate the file format
    validateFileFormat(filename:string) {
        let invalidFileList = [
            "exe", "bat", "sh", "cmd", "com", "scr", "msi", "reg", "app", "apk", "ipa", 
            "deb", "bin", "iso", "dmg", "torrent", "ace", "arj", "arc"
        ]; 
        const specialCharPattern = /[<>"=]/;
          if((invalidFileList.indexOf((filename).split('.')[1].toLowerCase())) > -1){
            this._snackBar.open('Invalid File' , '', {
                duration: 3000,
                horizontalPosition: 'end',
                verticalPosition: 'top'
            });
            return false;
          }else{
            let uploadArray = filename.split('.');
            if(specialCharPattern.test(uploadArray[0])){
                this._snackBar.open('ERROR: Invalid file name.The file name should not contain any of the following special characters <>"=' , '', {
                    duration: 5000,
                    horizontalPosition: 'end',
                    verticalPosition: 'top'
                });
                return false;
            }
            return true;
          }
    }
}

angular.module("comcast.directives").directive(
    'ctsQuickSendIsciPanel',
    downgradeComponent(
        // The inputs and outputs here must match the relevant names of the properties on the
        // "downgraded" component
        {component: CtsQuickSendIsciPanelComponent, inputs: ['spots', 'iscis', 'panel', 'thumbs', 'strict', 'ordertype', 'existing', 'filtered'], outputs: ['added', 'canceled']}));
